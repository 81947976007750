/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useCallback, useEffect, useState } from 'react';
import { styled, css, t, useTheme, SupersetTheme } from '@superset-ui/core';
import Icons from 'src/components/Icons';
import ControlHeader from 'src/explore/components/ControlHeader';
import { useComponentDidUpdate } from 'src/hooks/useComponentDidUpdate';
import Popover from 'src/components/Popover';
import Button from 'src/components/Button';
import { Form, FormItem } from 'src/components/Form';
import { Col, Row } from 'src/components';
import Select from 'src/components/Select/Select';
import {
  AddControlLabel,
  CaretContainer,
  Label,
  OptionControlContainer,
} from '../OptionControls';
import {
  COMPARATOR,
  ConditionalFormattingConfig,
  ConditionalFormattingControlProps,
  FormattingConfigurationRow,
  FormattingConfigurationFull,
  ConditionalFormattingRowProps,
  FormattingConfigProps,
} from './types';
import { FormattingPopover } from './FormattingPopover';

const FormattersContainer = styled.div`
  ${({ theme }) => css`
    padding: ${theme.gridUnit}px;
    border: solid 1px ${theme.colors.grayscale.light2};
    border-radius: ${theme.gridUnit}px;
  `}
`;

export const FormatterContainer = styled(OptionControlContainer)`
  &,
  & > div {
    margin-bottom: ${({ theme }) => theme.gridUnit}px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const CloseButton = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.grayscale.light1};
    height: 100%;
    width: ${theme.gridUnit * 6}px;
    border: none;
    border-right: solid 1px ${theme.colors.grayscale.dark2}0C;
    padding: 0;
    outline: none;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  `}
`;

const ConditionalFormattingControl = ({
  value,
  onChange,
  columnOptions,
  verboseMap,
  ...props
}: ConditionalFormattingControlProps) => {
  const theme = useTheme();
  const [conditionalFormattingConfigs, setConditionalFormattingConfigs] =
    useState<ConditionalFormattingConfig[]>(value ?? []);

  useEffect(() => {
    if (onChange) {
      onChange(conditionalFormattingConfigs);
    }
  }, [conditionalFormattingConfigs, onChange]);

  // remove formatter when corresponding column is removed from controls
  const removeFormattersWhenColumnsChange = useCallback(() => {
    const newFormattingConfigs = conditionalFormattingConfigs.filter(config =>
      columnOptions.some(option => option?.value === config?.column),
    );
    if (
      newFormattingConfigs.length !== conditionalFormattingConfigs.length &&
      onChange
    ) {
      setConditionalFormattingConfigs(newFormattingConfigs);
      onChange(newFormattingConfigs);
    }
  }, [JSON.stringify(columnOptions)]);
  useComponentDidUpdate(removeFormattersWhenColumnsChange);

  const onDelete = (index: number) => {
    setConditionalFormattingConfigs(prevConfigs =>
      prevConfigs.filter((_, i) => i !== index),
    );
  };

  const onSave = (config: ConditionalFormattingConfig) => {
    setConditionalFormattingConfigs(prevConfigs => [...prevConfigs, config]);
  };

  const onEdit = (newConfig: ConditionalFormattingConfig, index: number) => {
    const newConfigs = [...conditionalFormattingConfigs];
    newConfigs.splice(index, 1, newConfig);
    setConditionalFormattingConfigs(newConfigs);
  };

  const createLabel = ({
    column,
    operator,
    targetValue,
    targetValueLeft,
    targetValueRight,
  }: ConditionalFormattingConfig) => {
    const columnName = (column && verboseMap?.[column]) ?? column;
    switch (operator) {
      case COMPARATOR.NONE:
        return `${columnName}`;
      case COMPARATOR.BETWEEN:
        return `${targetValueLeft} ${COMPARATOR.LESS_THAN} ${columnName} ${COMPARATOR.LESS_THAN} ${targetValueRight}`;
      case COMPARATOR.BETWEEN_OR_EQUAL:
        return `${targetValueLeft} ${COMPARATOR.LESS_OR_EQUAL} ${columnName} ${COMPARATOR.LESS_OR_EQUAL} ${targetValueRight}`;
      case COMPARATOR.BETWEEN_OR_LEFT_EQUAL:
        return `${targetValueLeft} ${COMPARATOR.LESS_OR_EQUAL} ${columnName} ${COMPARATOR.LESS_THAN} ${targetValueRight}`;
      case COMPARATOR.BETWEEN_OR_RIGHT_EQUAL:
        return `${targetValueLeft} ${COMPARATOR.LESS_THAN} ${columnName} ${COMPARATOR.LESS_OR_EQUAL} ${targetValueRight}`;
      default:
        return `${columnName} ${operator} ${targetValue}`;
    }
  };

  return (
    <div>
      <ControlHeader {...props} />
      <FormattersContainer>
        {conditionalFormattingConfigs.map((config, index) => (
          <FormatterContainer key={index}>
            <CloseButton onClick={() => onDelete(index)}>
              <Icons.XSmall iconColor={theme.colors.grayscale.light1} />
            </CloseButton>
            <FormattingPopover
              title={t('Edit formatter')}
              config={config}
              columns={columnOptions}
              onChange={(newConfig: ConditionalFormattingConfig) =>
                onEdit(newConfig, index)
              }
              destroyTooltipOnHide
            >
              <OptionControlContainer withCaret>
                <Label>{createLabel(config)}</Label>
                <CaretContainer>
                  <Icons.CaretRight iconColor={theme.colors.grayscale.light1} />
                </CaretContainer>
              </OptionControlContainer>
            </FormattingPopover>
          </FormatterContainer>
        ))}
        <FormattingPopover
          title={t('Add new formatter')}
          columns={columnOptions}
          onChange={onSave}
          destroyTooltipOnHide
        >
          <AddControlLabel>
            <Icons.PlusSmall iconColor={theme.colors.grayscale.light1} />
            {t('Add new color formatter')}
          </AddControlLabel>
        </FormattingPopover>
      </FormattersContainer>
    </div>
  );
};

const rulesRequired = [{ required: true, message: t('Required') }];
const operatorOptions = [
  { value: COMPARATOR.EQUAL, label: '=' },
  { value: COMPARATOR.NOT_EQUAL, label: '≠' },
  { value: COMPARATOR.NONE, label: 'None' },
  { value: COMPARATOR.GREATER_THAN, label: '>' },
  { value: COMPARATOR.LESS_THAN, label: '<' },
  { value: COMPARATOR.GREATER_OR_EQUAL, label: '≥' },
  { value: COMPARATOR.LESS_OR_EQUAL, label: '≤' },
];

const colorSchemeOptions = (theme: SupersetTheme) => [
  { value: theme.colors.success.light1, label: t('green') },
  { value: theme.colors.alert.light1, label: t('yellow') },
  { value: theme.colors.error.light1, label: t('red') },
];

function ConfigRow(props: any) {
  return (
    <Form
      initialValues={props.initialValues}
      onValuesChange={props.onChange}
      requiredMark="optional"
      layout="vertical"
    >
      <Row gutter={12} align="middle">
        <Col span={4}>
          <Button buttonStyle="danger" onClick={props.removeHandler}>
            Remove
          </Button>
        </Col>
        <Col span={6}>
          <FormItem name="column" label={t('Column')} rules={rulesRequired}>
            <Select ariaLabel={t('Select column')} options={props.columns} />
          </FormItem>
        </Col>
        <Col span={6}>
          <FormItem name="operator" label={t('Operator')} rules={rulesRequired}>
            <Select
              ariaLabel={t('Select operator')}
              options={operatorOptions}
            />
          </FormItem>
        </Col>
        <Col span={6}>
          {props.initialValues.operator !== 'None' && (
            <FormItem
              name="targetValue"
              label={t('Value')}
              rules={rulesRequired}
            >
              <input type="text" />
            </FormItem>
          )}
        </Col>
      </Row>
    </Form>
  );
}

function FormattingConfig(props: FormattingConfigProps) {
  const [visible, setVisible] = useState(false);
  const theme = useTheme();
  const colors = colorSchemeOptions(theme);

  const removeRowHandler = (index: number) => {
    if (props.config.rows.length > 1) {
      const newRows = props.config.rows.filter((_, i) => i !== index);
      props.changeHandler({ ...props.config, rows: newRows });
    }
  };

  const addRowHandler = () => {
    const newRows = [
      ...props.config.rows,
      {
        targetValue: '',
        operator: COMPARATOR.EQUAL,
        column: props.columns[0]?.value,
      },
    ];
    props.changeHandler({ ...props.config, rows: newRows });
  };

  const colorChangeHandler = (color: string) => {
    props.changeHandler({ ...props.config, color });
  };

  const columnsToColorChangeHandler = (columnToColor: string[]) => {
    props.changeHandler({ ...props.config, columnToColor });
  };

  return (
    <FormatterContainer>
      <CloseButton onClick={props.deleteHandler}>
        <Icons.XSmall iconColor={theme.colors.grayscale.light1} />
      </CloseButton>

      <Popover
        visible={visible}
        onVisibleChange={setVisible}
        trigger={['click']}
        overlayStyle={{ width: '750px' }}
        content={
          <>
            <Row gutter={12} align="middle">
              <Col span={10}>
                <Select
                  onChange={columnsToColorChangeHandler}
                  value={props.config.columnToColor}
                  ariaLabel={t('Select columns')}
                  header={t('Select columns')}
                  options={props.columns}
                  maxTagCount={10}
                  mode="multiple"
                />
              </Col>
              <Col span={6}>
                <Select
                  onChange={colorChangeHandler}
                  ariaLabel={t('Select color')}
                  header={t('Select color')}
                  value={props.config.color}
                  options={colors}
                />
              </Col>
            </Row>
            {props.config.rows.map(
              (c: FormattingConfigurationRow, index: number) => (
                <ConfigRow
                  key={index}
                  columns={props.columns}
                  initialValues={c}
                  removeHandler={() => removeRowHandler(index)}
                  onChange={(newConfig: FormattingConfigurationRow) => {
                    const newRows = props.config.rows.map((r, i) =>
                      i === index ? { ...r, ...newConfig } : r,
                    );
                    props.changeHandler({ ...props.config, rows: newRows });
                  }}
                />
              ),
            )}
            <Button buttonStyle="primary" onClick={addRowHandler}>
              {t('Add new')}
            </Button>
          </>
        }
      >
        <OptionControlContainer withCaret>
          {props.config.rows.map((c: FormattingConfigurationRow) => (
            <Label key={c.column}>{c.column}</Label>
          ))}
          <CaretContainer>
            <Icons.CaretRight iconColor={theme.colors.grayscale.light1} />
          </CaretContainer>
        </OptionControlContainer>
      </Popover>
    </FormatterContainer>
  );
}

export const ConditionalFormattingControlRow = (
  props: ConditionalFormattingRowProps,
) => {
  const [configs, setConfigs] = useState<FormattingConfigurationFull[]>(
    Array.isArray(props.value) ? props.value : [],
  );
  const theme = useTheme();
  const colors = colorSchemeOptions(theme);

  React.useEffect(() => {
    props.onChange(configs);
  }, [configs]);

  return (
    <div>
      <ControlHeader label="Conditional Formatting Row" />
      <FormattersContainer>
        {configs.map((config, index) => (
          <FormattingConfig
            key={index}
            config={config}
            columns={props.columns}
            deleteHandler={() => {
              setConfigs(prev => prev.filter((_, i) => i !== index));
            }}
            changeHandler={(newConfig: FormattingConfigurationFull) =>
              setConfigs(prev =>
                prev.map((c, i) => (i === index ? newConfig : c)),
              )
            }
          />
        ))}

        <AddControlLabel
          onClick={() => {
            setConfigs(prev => [
              ...prev,
              {
                rows: [
                  {
                    targetValue: '',
                    operator: COMPARATOR.EQUAL,
                    column: props.columns[0]?.value,
                  },
                ],
                columnToColor: [],
                color: colors[0].value,
              },
            ]);
          }}
        >
          <Icons.PlusSmall iconColor={theme.colors.grayscale.light1} />
          {t('Add new color formatter')}
        </AddControlLabel>
      </FormattersContainer>
    </div>
  );
};

export default ConditionalFormattingControl;
