/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import Button from 'src/components/Button';
import { OnPasteSelect } from 'src/components/DeprecatedSelect';
import moment from 'moment';
import { DatePicker } from 'src/components/DatePicker';

// const MOMENT_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
const MOMENT_FORMAT = 'YYYY-MM-DD';

function isValidMoment(s) {
  /* Moment sometimes consider invalid dates as valid, eg, "10 years ago" gets
   * parsed as "Fri Jan 01 2010 00:00:00" local time. This function does a
   * better check by comparing a string with a parse/format roundtrip.
   */
  return s === moment(s, MOMENT_FORMAT).format(MOMENT_FORMAT);
}

export default class OnPasteWithCalendarSelect extends OnPasteSelect {
  constructor(props) {
    super(props);
    this.onPaste = this.onPaste.bind(this);

    this.state = {
      date: '',

      // react-datetime has a `closeOnSelect` prop, but it's buggy... so we
      // handle the calendar visibility here ourselves
      showCalendar: false,
      calendarViewMode: 'days',
    };

    this.isValidDate = this.isValidDate.bind(this);
    this.setCustomDate = this.setCustomDate.bind(this);
  }

  handleDocumentClick = e => {
    const isClosest =
      e.target.closest(`[data-id=datePickerWrapper]`) ||
      e.target.closest(`[data-id=datePickerBtn]`) ||
      e.target.closest('div.ant-picker-date-panel') ||
      e.target.closest('div.ant-picker-month-panel') ||
      e.target.closest('div.ant-picker-year-panel') ||
      e.target.closest('div.ant-picker-decade-panel');

    if (this.state.showCalendar && !isClosest) {
      this.setState({ showCalendar: false });
    }
  };

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  onPaste(evt) {
    if (!this.props.isMulti) {
      return;
    }
    evt.preventDefault();
    const clipboard = evt.clipboardData.getData('Text');
    if (!clipboard) {
      return;
    }
    const regex = `[${this.props.separator}]+`;
    const values = clipboard.split(new RegExp(regex)).map(v => v.trim());
    const validator = this.props.isValidNewOption;
    const selected = this.props.value || [];
    const existingOptions = {};
    const existing = {};
    this.props.options.forEach(v => {
      existingOptions[v[this.props.valueKey]] = 1;
    });
    let options = [];
    selected.forEach(v => {
      options.push({ [this.props.labelKey]: v, [this.props.valueKey]: v });
      existing[v] = 1;
    });
    options = options.concat(
      values
        .filter(v => {
          const notExists = !existing[v];
          existing[v] = 1;
          return (
            notExists &&
            (validator ? validator({ [this.props.labelKey]: v }) : !!v)
          );
        })
        .map(v => {
          const opt = { [this.props.labelKey]: v, [this.props.valueKey]: v };
          if (!existingOptions[v]) {
            this.props.options.unshift(opt);
          }
          return opt;
        }),
    );
    if (options.length) {
      if (this.props.onChange) {
        this.props.onChange(options);
      }
    }
  }

  setCustomDate(value) {
    const closeCalendar = this.state.calendarViewMode === 'days';

    let val = null;
    if (this.props.isMulti) {
      val = this.props.value ? this.props.value.slice() : [];
      if (typeof value === 'string') {
        val.push(value);
      } else {
        val.push(value.format(MOMENT_FORMAT));
      }
    } else {
      val = typeof value === 'string' ? value : value.format(MOMENT_FORMAT);
    }

    this.setState(prevState => ({
      date: val,
      showCalendar: prevState.showCalendar && !closeCalendar,
      calendarViewMode: closeCalendar ? 'days' : prevState.calendarViewMode,
    }));
    this.props.onChange(val);
  }

  isValidDate(date) {
    return (
      (this.state.value ? isValidMoment(this.state.value) : true) ||
      isValidMoment(date)
    );
  }

  render() {
    const { selectWrap: SelectComponent, ...restProps } = this.props;

    return (
      <div>
        <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <div style={{ width: '100%' }}>
            <SelectComponent {...restProps} onPaste={this.onPaste} />
          </div>
          <Button
            data-id="datePickerBtn"
            theme={this.props.theme}
            onClick={() =>
              this.setState(state => ({ showCalendar: !state.showCalendar }))
            }
          >
            <i className="fa fa-calendar" />
          </Button>
        </div>
        <div data-id="datePickerWrapper" style={{ height: 0 }}>
          <DatePicker
            data-id="datePicker"
            value={undefined}
            showToday={false}
            bordered={false}
            suffixIcon={false}
            onChange={value => this.setCustomDate(value)}
            allowClear={false}
            open={this.state.showCalendar}
            style={{
              height: 0,
              visibility: 'hidden',
            }}
          />
        </div>
      </div>
    );
  }
}
